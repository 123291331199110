import React from 'react';
import {
  useLocation,
  useHistory,
} from 'react-router-dom';
import StorageUtil from '../../util';

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Redirector: React.FC<Props> = (
) => {
  const query = useQuery();
  const siq_project = query.get('siq_project');
  const sc_surveyId = query.get('sc_surveyId');
  const history = useHistory();
  React.useEffect(() => {
    if (siq_project && sc_surveyId) {
      sessionStorage.setItem('siq_project', siq_project);
      sessionStorage.setItem('sc_surveyId', sc_surveyId);
      sessionStorage.setItem('appMode', 'surveyBuilderOnly');
      history.push(`/innerapp/${sc_surveyId}/builder`);
    } else {
      StorageUtil.cleanStorage();
      history.push('/');
    }
  }, [siq_project, sc_surveyId]);
  return (
    <div>
      Loading...
    </div>
  );
};

export type Props = {
}

Redirector.displayName = 'Redirector';
export default Redirector;
