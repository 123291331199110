import React from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop: React.FC<Props> = () => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

type Props = any;

export default ScrollToTop;
