import React from 'react';
import { InteractiveApp } from '@cbreone/micro-ui-interactive-survey';
import {
  surveyServiceBaseURL,
  BAMWSO2ClientID,
  BAMWSO2SecretID,
  mediaServiceBaseURL,
  interactiveSurveyOrigins,
  interactiveSurveyCoverDefaultImg,
} from '../../config';

const configData = {
  surveyServiceBaseUrl: surveyServiceBaseURL,
  propertyServiceBaseUrl: '',
  mediaRootUrl: '',
  wso2ClientId: BAMWSO2ClientID,
  wso2SecretId: BAMWSO2SecretID,
  mediaServiceBaseUrl: mediaServiceBaseURL,
  coverImg: interactiveSurveyCoverDefaultImg,
  origins: interactiveSurveyOrigins,
};

const InteractiveSurveyPage: React.FC<Props> = () => {
  // @ts-ignore:next-line
  const interactiveApp = () => <InteractiveApp configData={configData} />;
  return interactiveApp();
};

export type Props = {};

export default InteractiveSurveyPage;
