import React, { createContext, useContext } from 'react';

export type AppContextProps = {
  sideMenuOpen?: boolean;
  setSideMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>
  showAppShell?: boolean;
  isMobile?: boolean;
};

export const defaultAppContentProps = {
  sideMenuOpen: undefined,
  setSideMenuOpen: undefined,
  showAppShell: undefined,
  isMobile: undefined,
};

const AppContext = createContext<AppContextProps>(defaultAppContentProps);

export const useAppContext = (): AppContextProps => useContext(AppContext);

export default AppContext;
