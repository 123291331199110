import React from 'react';
import SurveyPage from '../pages/SurveyPage';
import NotFoundPage from '../pages/NotFoundPage';

type Route = {
  path?: string;
  exact?: boolean;
  component: React.FC<any>;
}
export type RouteParams = {
  id?: string;
}

const routes: Route[] = [
  {
    path: '/',
    exact: false,
    component: SurveyPage,
  },
  {
    path: '404',
    component: NotFoundPage,
  },
];

export default routes;
