import React from 'react';
import {
  BrowserRouter as Router, Route, Switch,
} from 'react-router-dom';
import App from '../App';
import InnerApp from '../InnerApp';
import Redirector from '../Redirector';

export default () => (
    <Router>
        <Switch>
            <Route path="/redirect">
                <Redirector/>
            </Route>
            <Route path="/innerapp">
                <InnerApp/>
            </Route>
            <Route path="/">
                <App/>
            </Route>
        </Switch>
    </Router>
);
