import React, { useState } from 'react';
import {
  IconButton, Menu, MenuItem, Theme,
} from '@material-ui/core';
import {
  createStyles, makeStyles,
} from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles((theme: Theme) => createStyles({
  configMenu: {
    position: 'fixed',
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: theme.zIndex.appBar + 1,
  },
}));

const ConfigMenu: React.FC<Props> = ({
  showAppShell,
  onToggleAppShell,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.configMenu}>
      <IconButton onClick={handleClick}>
        <SettingsIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
      >
        <MenuItem
          onClick={() => {
            onToggleAppShell(!showAppShell);
            handleClose();
          }}
        >
          Show App Shell
          {showAppShell ? <CheckIcon /> : null}</MenuItem>
      </Menu>
    </div>
  );
};

type Props = {
  showAppShell: boolean;
  onToggleAppShell: (showAppShell: boolean) => void;
};

export default ConfigMenu;
