import React, { Suspense } from 'react';
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  CssBaseline, ThemeProvider, useMediaQuery,
} from '@material-ui/core';
import internalTheme from '@cbreone/core-ui/dist/themes/internalTheme';
import AuthRouter from '@cbreone/core-services/dist/components/AuthRouter';
import classnames from 'classnames';

import ErrorBoundary from '../../components/ErrorBoundary';
import ScrollToTop from '../ScrollToTop';
import routes from '../../routes/routes';

const drawerWidth = 300;
// eslint-disable-next-line no-shadow
const useStyles = makeStyles((theme: Theme) => createStyles({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  contentShift: {
    marginLeft: drawerWidth,
    transition: theme.transitions.create('margin', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.easeOut,
    }),
  },
}));

export default () => {
  const classes = useStyles();

  if (!useMediaQuery(internalTheme.breakpoints.up('xs'))) {
    return null;
  }

  return (
    <ThemeProvider theme={internalTheme}>
      <AuthRouter>
          <CssBaseline />
          <div
            className={classnames(classes.content)}>
            <BrowserRouter>
              <ScrollToTop />
              <Suspense fallback={<div />}>
                <Switch>
                  {routes.map(({
                    path,
                    exact,
                    component: Component,
                  }) => ((
                    <Route
                      path={path}
                      exact={exact}
                      render={(props) => (
                        <ErrorBoundary
                          isDeveloper={true}
                        >
                          <Component {...props} />
                        </ErrorBoundary>
                      )}
                      key={path}
                    />
                  )))}
                </Switch>
              </Suspense>
            </BrowserRouter>
          </div>
      </AuthRouter>
    </ThemeProvider>
  );
};
