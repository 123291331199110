import React from 'react';
import { SurveyApp } from '@cbreone/survey-ui';
import { useAppContext } from '../../app/App/AppContext';

const SurveyPage: React.FC<Props> = () => {
  const {
    sideMenuOpen,
    showAppShell,
    isMobile,
  } = useAppContext();
  const position = showAppShell
    ? {
      top: isMobile ? 56 : 64,
      left: sideMenuOpen ? 300 : 0,
    } : {};
  // @ts-ignore:next-line
  const surveyApp = () => <SurveyApp position={position} />;
  return surveyApp();
};

export type Props = {};

export default SurveyPage;
