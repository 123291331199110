import React from 'react';
import {
  createStyles, makeStyles, Theme, useTheme,
} from '@material-ui/core/styles';
import {
  AppBar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import classnames from 'classnames';
import { signOutUser } from '@cbreone/core-services/dist/services/user-service';
import Analytics from '@cbreone/core-services/dist/services/analytic-service';
import { internalTheme } from '@cbreone/core-ui/dist/themes';
import { useAppContext } from '../App/AppContext';

const drawerWidth = 300;
const useStyles = makeStyles((theme: Theme) => createStyles({
  appBar: {
    backgroundColor: internalTheme.palette.grey[200],
    color: internalTheme.palette.primary.main,
    transition: theme.transitions.create(['margin', 'width'], {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  drawer: {
    flexShrink: 0,
    width: drawerWidth,
  },
  drawerHeader: {
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  cbreIcon: {
    height: 30,
    marginRight: 10,
    width: 30,
    cursor: 'pointer',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  userInfo: {
    float: 'right',
    fontSize: '16px',
    marginLeft: 'auto',
    marginRight: '50px',
  },
}));

const AppShell: React.FC<Props> = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    sideMenuOpen, setSideMenuOpen = () => ({ }),
  } = useAppContext();
  const displayName = localStorage.getItem('displayName');
  // temporary until we can find out why link won't redirect to root with nested routers
  const redirect = (href: string) => () => { window.location.href = href; };

  React.useEffect(() => {
    window.addEventListener('load', () => {
      Analytics.setUser();
    });

    return () => {
      window.removeEventListener('load', () => {
        Analytics.setUser();
      });
    };
  });

  return (
    <>
      <AppBar
        position="fixed"
        className={classnames(classes.appBar, { [classes.appBarShift]: sideMenuOpen })}>
        <Toolbar>
          <IconButton
            id="SC-HamburgerNav"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            className={classes.menuButton}
            onClick={() => setSideMenuOpen(!sideMenuOpen)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">
            CBRE ONE
          </Typography>
          {
            displayName
              ? <Typography className={classes.userInfo}>Welcome, {displayName} </Typography>
              : <></>
          }
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        className={classes.drawer}
        open={sideMenuOpen}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List>
          <ListItem key="Menu">
            <ListItemIcon
              onClick={redirect('/')}
            >
              <img
                alt="logo"
                className={classes.cbreIcon}
                src="/cbre.png"
              />
            </ListItemIcon>
            <ListItemText primary="Menu" />
            {isMobile && (
              <IconButton aria-label="close" onClick={() => setSideMenuOpen(!sideMenuOpen)}>
                <CloseIcon />
              </IconButton>
            )}
          </ListItem>
          <ListItem
            button
            key="surveys"
            onClick={redirect('/')}
          >
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Surveys" />
          </ListItem>
          <ListItem
            button
            onClick={signOutUser}
          >
          <ListItemIcon>
            <ExitToAppIcon />
           </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export type Props = {};

export default AppShell || undefined;
