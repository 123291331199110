type StorageUtil = {
  cleanStorage: any,
}

const cleanStorage = () => {
  sessionStorage.removeItem('siq_project');
  sessionStorage.removeItem('sc_surveyId');
  sessionStorage.removeItem('appMode');
};

export default {
  cleanStorage,
} as StorageUtil;
