import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import {
  createStyles, makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  errorBoundary: {
    pre: {
      whiteSpace: 'pre-wrap',
    },
  },
}));

const ErrorBoundaryDisplay: React.FC<Props & { error: State['error'] }> = ({
  ctaText = 'Retry',
  isDeveloper = false,
  message = 'Please try refreshing your browser. If this problem persists, you can contact our support team using the chat tool at the top of the page.',
  title = 'Oops! Something went wrong.',
  error,
  onRetry,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.errorBoundary}>
        <h3>{title}</h3>
        <p>{message}</p>
        {onRetry && (
          <Button color="primary" variant="contained" onClick={onRetry}>{ctaText}</Button>
        )}
      {isDeveloper && (
        error && (
          <>
            <h3 className="error-title">Error Message:</h3>
            <pre>{error.message}</pre>
            <h3 className="error-title">Stacktrace:</h3>
            <pre>{error.stack}</pre>
            <h3 className="error-title">Component stack error:</h3>
            <pre>{error.componentStack}</pre>
          </>
        )
      )}
    </div>
  );
};
/**
 * ErrorBoundary catches render errors and shows a title and message to the user based on props.
 * if isDeveloper is true it will then show the errors that have been caught.
 * The refresh button will try to remount the children and clear out the error to see if that fixes the error.
 * onRefresh will notify the parent component when onRefresh happens
 */
class ErrorBoundary extends Component<Props, State> {
  /**
   * gets called first, so we set the error state to true to prevent more errors from happening
   */
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: undefined,
    };
  }

  /**
   * error contains the error message and the stack trace
   * the componentError contains the component stack error
   */
  componentDidCatch({ message = '--', stack = '--' }, { componentStack = '--' }) {
    this.setState({
      error: {
        message,
        stack,
        componentStack: componentStack.trim(),
      },
    });
  }

  retry = () => {
    if (this.props.onRetry) {
      this.setState({
        hasError: false,
        error: undefined,
      });
      this.props.onRetry();
    }
  }

  render() {
    const {
      children,
      ...other
    } = this.props;
    return this.state.hasError
      ? <ErrorBoundaryDisplay
        {...other}
        error={this.state.error}
        onRetry={this.retry}
      />
      : this.props.children;
  }
}

export type Props = {
  ctaText?: string;
  isDeveloper?: boolean;
  message?: string;
  title?: string;
  onRetry?: () => void;
  children?: any
}

export type State = {
  hasError: boolean;
  error?: {
    message: string;
    stack: string;
    componentStack: string
  };
}

export default ErrorBoundary;
