import React, { Suspense, useState } from 'react';
import {
  BrowserRouter, Route, Router, Switch, useHistory,
} from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  CssBaseline, ThemeProvider, useMediaQuery,
} from '@material-ui/core';
import internalTheme from '@cbreone/core-ui/dist/themes/internalTheme';
import AuthRouter from '@cbreone/core-services/dist/components/AuthRouter';
import classnames from 'classnames';

import AppContext from './AppContext';

import ErrorBoundary from '../../components/ErrorBoundary';
import ScrollToTop from '../ScrollToTop';
import ConfigMenu from '../ConfigMenu';
import AppShell from '../AppShell';
import routes from '../../routes/routes';

import StorageUtil from '../../util';
import InteractiveSurveyPage from '../../pages/InteractiveSurveyPage';

const drawerWidth = 300;
// eslint-disable-next-line no-shadow
const useStyles = makeStyles((theme: Theme) => createStyles({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  contentShift: {
    marginLeft: drawerWidth,
    transition: theme.transitions.create('margin', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.easeOut,
    }),
  },
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useMediaQuery(internalTheme.breakpoints.down('sm'));
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  // app mode : 1) fullFeatures 2) surveyBuilderOnly
  StorageUtil.cleanStorage();
  const appMode = localStorage.getItem('appMode') || 'fullFeatures';
  const [showAppShell, setShowAppShell] = useState(JSON.parse(localStorage.getItem('showAppShell') || 'true'));
  const [hideConfigMenu, setHideConfigMenu] = useState(JSON.parse(localStorage.getItem('hideConfigMenu') || 'false'));
  React.useEffect(() => {
    setSideMenuOpen(!isMobile && sideMenuOpen);
    if (hideConfigMenu == null) {
      setHideConfigMenu(false);
    }
  }, [isMobile, hideConfigMenu, sideMenuOpen]);

  if (!useMediaQuery(internalTheme.breakpoints.up('xs'))) {
    return null;
  }

  const handleToggleAppShell = (show: boolean) => {
    localStorage.setItem('showAppShell', JSON.stringify(show));
    setShowAppShell(show);
  };

  return (
    <>
    <Router history={history}>
      <Switch>
        <Route path="/:id/interactive">
          <InteractiveSurveyPage />
        </Route>
        <Route path="/">
        <ThemeProvider theme={internalTheme}>
      <AuthRouter>
        <AppContext.Provider
          value={{
            sideMenuOpen,
            setSideMenuOpen,
            showAppShell,
            isMobile,
          }}
        >
          <CssBaseline />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: appMode === 'fullFeatures' && showAppShell && sideMenuOpen,
            })}>
            <BrowserRouter>
              <ScrollToTop />
              <Suspense fallback={<div />}>
                { appMode === 'fullFeatures' && showAppShell && (<>
                  <Route key="app-shell" path="/" render={() => <AppShell />} />
                </>)}
                <Route
                  key="config-menu" path="/" render={() => (
                    appMode === 'fullFeatures' && !hideConfigMenu && <ConfigMenu
                      showAppShell={showAppShell}
                      onToggleAppShell={handleToggleAppShell}
                    />
                  )}
                />
                <Switch>
                  {routes.map(({
                    path,
                    exact,
                    component: Component,
                  }) => (
                    <Route
                      path={path}
                      exact={exact}
                      render={(props) => (
                        <ErrorBoundary
                          isDeveloper={true}
                        >
                          <Component {...props} />
                        </ErrorBoundary>
                      )}
                      key={path}
                    />
                  ))}
                </Switch>
              </Suspense>
            </BrowserRouter>
          </div>
        </AppContext.Provider>
      </AuthRouter>
    </ThemeProvider>
        </Route>
      </Switch>
    </Router>
    </>
  );
};
